<template>
  <div>
    <Teleport to="#header-buttons">
      <div id="tooltip-button-disable">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="bannerCampaignLoading"
          @click="addCampaign"
        >
          Add Campaign
        </b-button>
      </div>
    </Teleport>
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
      >
        <b-skeleton-wrapper :loading="bannerCampaignLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <div
            v-if="allBannerCampaigns.length === 0"
            class="text-center mt-5 font-large-1 text-black-50"
          >No Ads Found</div>
          <banner-ads-collapse-item
            v-for="(bannerCampaigns, index) in allBannerCampaigns"
            :id="bannerCampaigns.id"
            :key="index"
            :title="bannerCampaigns.name"
            :time="bannerCampaigns.time"
            :campaign="bannerCampaigns"
            :is-active="bannerCampaigns.is_active"
            icon="EditIcon"
            icons="TrashIcon"
            :src="null"
            @deleteCampaign="deleteCampaign"
            @editCampaign="editCampaign"
            @getAllBannerCampaigns="getAllBannerCampaigns"
          >
            <div class="heading-elements">
              <b-table
                ref="mediaTable"
                :items="bannerCampaigns.banner_campaign_has_media"
                responsive
                selectable
                :select-mode="selectMode"
                :fields="tableColumns"
                show-empty
                empty-text="No matching records found"
                class="float: right"
                th-class="text"
                @row-selected="onRowSelected($event, index)"
              >
                <!-- Column: Media -->
                <template #cell(MEDIA)="data">
                  <b-media no-body>
                    <span class="mr-2 ml-0">
                      {{ data.index + 1 }}
                    </span>
                    <b-media-aside class="mr-1 ml-0">
                      <b-img
                        :src="getThumbnailOfFile(data.item.media)"
                        class="image"
                        rounded
                        alt="data.json"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-link>
                        <span class="media-body">
                          {{ data.item.media.name }}
                        </span>
                      </b-link>
                    </b-media-body>
                  </b-media>
                </template>
                <!-- Column: Duration -->
                <template
                  #cell(DURATION)="data"
                  class="BannerMode"
                >
                  <span class="text-nowrap">
                    {{ data.item.media.duration }}
                  </span>
                </template>
                <!-- Column: Type -->
                <template
                  #cell(TYPE)="data"
                  class="BannerMode center"
                >
                  <div style="text-align: center">
                    <b-img
                      width="30px"
                      height="30px"
                      style="padding: 7px"
                      :src="data.item.media.type_main === MediaType.IMAGE ? require('@/assets/images/simiicons/Image.svg') : require('@/assets/images/simiicons/Video.svg')"
                    />
                    <span class="text-nowrap">
                      {{ data.item.media.type }}
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
          </banner-ads-collapse-item>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div>
      <div class="text-nowrap" />
    </div>
  </div>
</template>
<script>
import {
  BMedia, BLink, BButton, BTable, BImg, BMediaBody, BMediaAside, BSkeletonWrapper, BSkeleton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Teleport from 'vue2-teleport'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import BannerAdsCollapseItem from '@/components/bannerads/BannerAdsCollapseItem.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import {
  confirmDeletion, getThumbnailOfFile, showErrorMessage, showToast,
} from '@/common/global/functions'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

export default {
  components: {
    BannerAdsCollapseItem,
    AppCollapse,
    BButton,
    BTable,
    BMedia,
    BLink,
    BImg,
    BMediaBody,
    BMediaAside,
    BSkeletonWrapper,
    BSkeleton,
    Teleport,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    if (!memberPermission) {
      next({
        name: 'dashboard',
      })
    } else { next() }
  },
  data() {
    return {
      MediaType,
      active: false,
      bannerCampaignLoading: false,
      id: '',
      campaign: '',
      allBannerCampaigns: [],
      selectMode: 'single',
      tableColumns: [{ key: 'MEDIA' }, { key: 'DURATION' }, { key: 'TYPE', thClass: 'text-center' }],
      tableRefIndex: -1,
    }
  },
  computed: {
  },
  mounted() {
    this.getAllBannerCampaigns()
    // this.toolTipActiveFunc()
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    addCampaign() {
      this.$router.push('/banner-ads/add-campaigns')
    },
    async getAllBannerCampaigns() {
      this.bannerCampaignLoading = true
      try {
        const res = await this.$axios.get('banner-campaigns')
        const { data: { data } } = res || {}
        this.allBannerCampaigns = data
        this.bannerCampaignLoading = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Internal Ad Campaigns', 'You are not authorized for this action!', 'danger')
          this.bannerCampaignLoading = false
        }
      } finally {
        this.bannerCampaignLoading = false
      }
    },
    deleteCampaign(obj) {
      this.id = obj.id
      confirmDeletion().then(async result => {
        if (result.value) {
          this.deleteBannerAd(this.id)
        }
      })
    },
    deleteBannerAd(id) {
      this.$axios
        .delete(`banner-campaigns/${id}`)
        .then(async ({ data }) => {
          const { data: dataRes } = data || {}
          await this.getAllBannerCampaigns()
          showToast('Delete Internal Network Ad', 'Internal Network Ad has been deleted successfully!')
          return { dataRes }
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Delete Internal Network Ad', message.toString(), 'danger')
          }
        })
    },
    editCampaign(obj) {
      this.$router.push(`/banner-ads/edit-campaigns/${obj.id}`)
    },
    async onRowSelected(items, index) {
      if (items[0] && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable[index].clearSelected()
    },
  },
}
</script>

<style>
.image {
    width: 32px;
    height: 32px;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
}
.center{
  float: right!important;
}
#disabled-button-wrapper .btn[disabled] {
  /* you need this line, not to block hover event from div */
  pointer-events: none;
}
#disabled-button-wrapper {
  display: inline-block; /* display: block works as well */
  margin: 50px; /* make some space so the tooltip is visible */
}
</style>
